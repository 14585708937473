<script>
import Icon from '../Icon'

export default {
  name: 'AppButton',
  components: { Icon },
  props: {
    busy: { type: Boolean, default: false },
    variant: { type: String, default: 'turquoise' },
    outline: { type: Boolean, default: true },
    border: { type: Boolean, default: true },
    icon: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    className() {
      let className = [
        'border-2',
        'font-semibold',
        `transition duration-300`,
      ]

      if (this.variant === 'turquoise') {
        className = [
          ...className,
          'text-white',
          'hover:border-turquoise',
          'hover:bg-white',
          `hover:text-${this.variant}`,
        ]
      }

      if (this.variant === 'grey') {
        className = [
          ...className,
          'text-white',
          'hover:border-grey-dark',
          'hover:bg-grey-dark',
          `hover:text-${this.variant}`,
        ]
      }

      if (this.variant === 'white') {
        className = [
          ...className,
          'text-turquoise',
          'hover:border-white',
          'hover:bg-turquoise',
          `hover:text-${this.variant}`,
        ]
      }

      if (this.variant === 'transparent') {
        className = [
          ...className,
          'text-gray-600',
          'hover:border-transparent',
          `hover:text-turquoise`,
        ]
      }

      if (this.variant === 'red') {
        className = [
          ...className,
          'text-white',
          'bg-red',
          'hover:border-red',
          'hover:bg-white',
          `hover:text-red`,
        ]
      }

      return [
        ...className,
        this.busy ? 'btn-loading' : '',
        this.disabled
          ? 'cursor-not-allowed pointer-events-none bg-grey-dark border-grey-dark'
          : ` border-${this.variant} bg-${this.variant}`,
      ]
    },
  },
}
</script>

<template>
  <button
    class="relative flex items-center justify-center h-8 px-4 rounded-full outline-none focus:outline-none"
    :disabled="disabled"
    :class="[className]"
  >
    <span class="block">
      <Icon v-if="icon" :icon="icon" class="mr-3" /> <slot />
    </span>
  </button>
</template>

<style scoped>
.btn-loading {
  pointer-events: none;
}

.btn-loading span {
  pointer-events: none;
  color: transparent !important;
}

.btn-loading:before {
  content: '';
  display: block;
  width: 1em;
  height: 1em;
  border-width: 2px;
  border-color: currentColor;
  border-style: solid;
  border-right-color: transparent;
  border-top-color: transparent;
  position: absolute;
  left: calc(50% - (1em / 2));
  top: calc(50% - (1em / 2));
  border-radius: 50%;
  animation: rotate 0.5s infinite linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
